const LIST_MAPPING = {
	usa: 'YwQ5L6',
	newsletter: 'VU3xye'
};
export type KlaviyoList = keyof typeof LIST_MAPPING;

export const createSubscription = async (email: string, list: KlaviyoList, props: object) => {
	const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=VWhMJc';
	const options = {
		method: 'POST',
		headers: { revision: '2023-10-15', 'content-type': 'application/json' },
		body: JSON.stringify({
			data: {
				type: 'subscription',
				attributes: {
					profile: {
						data: {
							type: 'profile',
							attributes: {
								email,
								properties: {
									...props
								}
							}
						}
					}
				},
				relationships: { list: { data: { type: 'list', id: LIST_MAPPING[list] } } }
			}
		})
	};

	return fetch(url, options);
};

export const subscribeToBackInStock = (email: string, variantId: string) => {
	const url = 'https://a.klaviyo.com/client/back-in-stock-subscriptions/?company_id=VWhMJc';
	const options = {
		method: 'POST',
		headers: {
			accept: 'application/json',
			revision: '2023-12-15',
			'content-type': 'application/json'
		},
		body: JSON.stringify({
			data: {
				type: 'back-in-stock-subscription',
				attributes: {
					channels: ['EMAIL'],
					profile: {
						data: {
							type: 'profile',
							attributes: {
								email
							}
						}
					}
				},
				relationships: {
					variant: {
						data: {
							type: 'catalog-variant',
							id: `$shopify:::$default:::${variantId}`
						}
					}
				}
			}
		})
	};

	return fetch(url, options);
};
